.component__radnikBonus {
    width: 90%;
    margin: auto;
    display: flex;
    position: relative;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid gray;
    p {
        width: 17%;
        text-align: center;
    }
    &__spinner {
        position: absolute;
        top: 0;
        left: 20%;
        height: 100%;
        width: 80%;
        background-color: #fafafa;
        display: flex;
        justify-content: center;
        img{
            height: 100%;
        }
    }
}
