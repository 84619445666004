.components__bonus{
    box-shadow: 0px 4px 10px rgba(82, 82, 96, 0.14);
   width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 10px;
    background-color: #fcfcfc33;
    p{
        margin: 3px 0;
    }
    &__header{
        display: flex;
        justify-content: space-between;
        p{
            color: #969698;
        }

    }
    &__content{
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid black;
        p{
            color: #11002F;
        }
    }
}