.component__ccpromet{
    width: 250px !important;
    &__head{
        display: flex;
        justify-content: space-between;
        color: #969698;
        margin: 0;
    }
    &__content{
        display: flex;
        justify-content: space-between;
        margin: 0;
        font-size: 18px;
        font-weight: bold;
    }
    p{
        margin: 0;
    }
}