.component__bonus{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    &__month{
        display: flex;
        width: 50%;
        justify-content: space-around;
        div{
            background-color: #eaf6ff;
            width: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border-radius: 10px;
            h4{
                margin: 10px 0;
            }
            p{
                cursor: pointer;
                width: 100%;
                text-align: center;
                transition: all 0.3s ease;
                margin: 5px 0;
                border-radius: 10px;
            }
        }
        &__transparent{
            background-color: white !important;
        }
    }
    button{
        width: 200px;
        height: 40px;
        border: none;
        background-color: #eaf6ff;
        cursor: pointer;
        border: 1px solid gray;
        border-radius: 10px;
    }
    &__tabela{
        width: 100%;
        &__header{
            width: 90%;
            display: flex;
            margin: auto;
            border-bottom: 1px solid gray;
            p{
                width: 17%;
                text-align: center;
                box-sizing: border-box;
                padding: 10px;
                font-weight: 900;
            }
        }
    }
    .godinaActive, .mjesecActive{
        width: 100%;
        background-color:#6bb2e9 ;
    }
}