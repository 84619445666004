.component__login {
    width: 100%;
    min-height: 100vh;
    background-color: #3d3d51;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    
    position: relative;
    z-index: 1000000;

    @media screen and (max-width: 700px) {
       flex-direction: column;
        
    }
    
    &__left{
        width: 70%;
        max-width: 100%;
        height: 100vh;
        background-image: url('./sl\ 2.svg');
        background-size: cover;
        box-sizing: border-box;
        padding: 50px 300px;
        @media screen and (max-width: 1400px) {
            padding: 20px 50px;
            
        }
        @media screen and (max-width: 1024px) {
            background-position: right;
            width: 50%;
            
        }
        @media screen and (max-width: 700px) {
            display: none;
             
         }
        *{
            max-width: 100%;
        }
        img{
            height: 50px;
        }
        h3{
            color: #11002F;
            font-size: 16px;
            margin: 0;
            margin-top: 200px;
            @media screen and (max-width: 1400px) {
                margin-top: 30px;
                
            }
            
        }
        h1{
            color: #11002F;
            font-size: 60px;
            font-weight: bold;
            width: 400px;
            margin: 0 ;
        }
    }
    &__right{
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 1024px) {
            width: 50%;
            
        }
        @media screen and (max-width: 700px) {
            width: 100%;
             
         }
        p{
            color: #999FAE;
            width: 250px;
            margin: 0;
           
        }
        h1{
            width: 250px;
            color: white;
            margin-top: 0;
            margin-bottom: 50px;
            

        }
        label{
            width: 250px;
            color: #999FAE;
        }
        input{
            width: 250px;
            height: 50px;
            box-sizing: border-box;
            border-radius: 5px;
            background-color: transparent;
            outline: none;
            border: #E8E8E8 1px solid;
            padding: 20px 17px;
            margin: 11px 0;
            color: #999FAE;

        }
        ::placeholder{
            color: #999FAE;
        }
        button{
            margin-top: 70px;
            width: 250px;
            background-color: #6664E5;
            border-radius: 5px;
            color: white;
            height: 50px;
            border: none;
            outline: none;
            cursor: pointer;
        }
    }
}
