.components__bestcc {
    width: 100%;
    min-height: 100px;
    box-sizing: border-box;
    border-radius: 10px;

    background-color: #fcfcfc33;
    @media screen and (max-width: 1024px) {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    div {
        box-shadow: 0px 4px 10px rgba(82, 82, 96, 0.14);
        display: flex;
        padding: 10px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 10px;
        margin-bottom: 20px;
        position: relative;
        @media screen and (max-width: 1024px) {
            width: 30%;
            min-width: 250px;
            border-bottom: 1px solid black !important;
        }
        h3{
            margin-top: 0;
        }
        p {
            width: 100%;
            text-align: center;
        }
        img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
        }
        
    }
}
