.component__pregled {
    width: 100%;
    height: 100vh;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.component__pregled__jedan {
    width: 50%;
    height: 50%;
    background-color: blue;
    display: flex;
    justify-content: center;
    align-items: center;
    &__dva {
        width: 50%;
        height: 50%;
        background-color: green;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 1500px) {
            width: 100%;
            
        }
    }
}