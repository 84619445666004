.components__radnik {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: hsl(0, 0%, 100%);
    max-height: 40px;
    
    @media screen and (max-width: 1024px) {
        flex-direction: column;
        max-height: none;
        .border,
        .border2 {
            display: none !important;
        }
    }
    p {
        text-align: center;
        width: 20%;
        font-weight: 400;
        margin: 5px 0;
        @media screen and (max-width: 1024px) {
            width: 100%;
        }
        p {
            width: 100%;
        }
    }
    .imeradnik{
        width: 10% !important;
        @media screen and (max-width: 1024px) {
            width: 100%;
        }
    }
    &__headMobile {
        display: none;
        @media screen and (max-width: 1024px) {
            display: block;
            width: 100% !important;
            text-align: center;
        }
    }
    .tableRow__progres{
        width: 85%;
        background-color: white !important;
        height: 20px;
        border-radius: 10px;
        position: relative;
        div{
            height: 100%;
            border-radius: 10px;
        }
        p{
            position: absolute;
            top: 0;
            width: 100% !important;
            text-align: center;line-height: 20px;
            margin: 0;
        }
    }

    @media screen and (max-width: 1024px) {
        .tableRow {
            p {
                width: 30% !important;
                text-align: center !important;
            }
            &__progres p{
                width: 100% !important;
            }
            
        }
    }
}
.radnik2 {
    background-color: #f0f1f9;
}
