.components__kpi{
    width: 32%;
    border-radius: 10px;
    height: 150px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgb(82 82 96 / 14%);
    background-color: #fcfcfc33;
    min-width: 400px;
    @media screen and (max-width: 1024px) {
        margin-bottom: 20px;
        
    }
    h1{
        margin: 0;
        font-size: 20px;
        width: 100%;
    }
    h4{
        margin: 0;
        width: 100%;
    }
    p{
        margin: 0;
        text-align: center;
        font-size: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        p{
            width: 25%;
            text-align: center;
            display: block;
            &:first-child{
                text-align: left;
            }
        }
        
    }
    div{
        width: 100%;
    }
    &__content2{
        p{
            p{
                width: 50%;
            }
        }
    }
    
}