.components__popup{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 10;
    justify-content: space-around;
    background-color: #fcfcfc7c;
    overflow-x: hidden;
    overflow-y: auto;
    background-color:#F2F0F9 ;
    overflow: hidden;
    height: 0;
    transition: height 0.3s ease;

    &__head{
        text-align: center;
        width: 90%;
        border-bottom: 1px solid black;
        margin-top: 10px;
        margin-bottom: 10px;
        height: 50px;
        margin: auto;
    }
    &__container{
        width: 100%;
        min-height: 100px;
        box-sizing: border-box;
        padding: 20px;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        border-radius: 10px;
        margin-left: 10px;
        margin-top: 20px;
        .components__kpi{
            width: 100% !important;
            height: 200px!important;
            background-color: rgba(255, 255, 255, 0.336);
        }
        .component__table__kpiContainer__leads{
            width: 100% !important;
            height: 200px!important;
        }
        .components__minimum{
            width: 100% !important;
            margin-top: 10px;
            margin-bottom: 0;
        }

        &__cc{
            box-shadow: 0px 4px 10px rgba(82, 82, 96, 0.14);
            box-sizing: border-box;
            padding: 10PX;
            width: 22%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            margin-top: 20px;
            flex-wrap: wrap;
            h3{
                width: 100%;
                text-align: center;
                font-size: 24px;

            }
        }
    }
}
.rewardContainer{
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 90vh;
    div{
        flex-direction: column-reverse;
        align-items: center;
        img{
            width: 200px;
            height: auto;
        }
    }
}
.components__popupV{
    height: 100%;
}