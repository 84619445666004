.components__minimum__progres{
    width: 70%;
    margin: 0 5%;
    margin-top: 10px;
    height: 20px;
    border-radius: 30px;
    background-color: white;
    position: relative;
    &__bar{
        height: 20px;
        border-radius: 30px;
        background-color: red;
        color: white;
        
    }
    div{
        max-width: 100%;
    }
    p{
        position: absolute;
        right: -30%;
        top: 0;
        margin: 0;
        color: black;
        font-weight: 900;
    }
}
.components__minimum{
    padding: 7px 0;
    border-radius: 5px;
    margin-bottom: 20px;
    
    box-shadow: 0px 0px 4px 0px #2b2b3940;
    h5{
        width: 90%;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        margin-top: 5px;
        strong{
            font-weight: 900;
        }
    }
}
.components__minimumRed{
    background-color: #F9F0F0;
    
}
.components__minimumOrange{
    background-color: #fff6ea;
    
}
.components__minimumGreen{
    background-color: #EAF6FF;
    
}