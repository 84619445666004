@keyframes phone {
  from {
    transform: rotate(10deg);
  }
  to {
    transform: rotate(0deg);
  }
}


.component__table {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 50px;
  zoom: 0.6;
  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid gray;
    box-sizing: border-box;
    padding: 10px;
    &__teamLeader {
      display: flex;
      align-items: center;
      &__circle {
        width: 50px;
        height: 50px;
        background-color: orange;
        margin-right: 10px;
        border-radius: 50%;
      }
    }
    @media screen and (max-width: 1024px){
      flex-direction: row-reverse;
      div{
        display: none !important;
      }
      
      
    }
  }
  &__tableData {
   
    box-shadow: 0px 4px 10px rgba(82, 82, 96, 0.14);
    border-radius: 10px;
    width: 100%;
    overflow: hidden;
    margin: auto;
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-around;
      @media screen and (max-width: 1024px) {
        display: none;
        
      }
      p {
        text-align: center;
        width: 20%;
        span {
          width: 33%;
          text-align: center;
        }
      }
    }
    div:last-child {
      background-color: rgba(255, 0, 0, 0.247) !important;
    }
    div:nth-last-child(-n + 2) {
      background-color: rgba(255, 93, 93, 0.226);
    }
    div:nth-child(2) {
      background-color: rgba(0, 128, 0, 0.253);
    }
  }
  &__navigation {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 0.3s ease;
    background-color: rgba(0, 0, 0, 0.507);
    opacity: 1;
    z-index: 1;
    div {
      background-color: #6664e5;
      color: white;
      text-align: center;
      padding: 10px;
      margin: 5px;
      border-radius: 10px;
      cursor: pointer;
      width: 200px;
      transition: all 0.3s ease;
    }
    &__active {
      background-color: green !important;
    }
  }
  &__navigationHidden {
    opacity: 0;
    z-index: -1;
  }
  &__navigationButton {
    position: fixed;
    top: 0;
    width: 100px;
    height: 20px;
    left: calc(50% - 50px);
    background-color: #f0f1f9;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      width: 30px;
      height: 3px;
      background-color: gray;
    }
  }
  &__leads {
  }
  &__obi {
    width: 100%;
    margin-top: 20px;
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;
    h3{
        text-align: center;
        font-size: 20px;
        margin: 0;
    }
    h1{
      text-align: center;
      font-size: 23px;
      margin:  0;
    }
  }
  &__time {
    justify-self: flex-end;
    width: 150px;
  }
  &__kpiContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px 0;
    width: 100%;
    flex-wrap: wrap;
    margin: auto;
    
    &__leads {
      width: 32%;
      background-color: #fcfcfc33;
      border-radius: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100px;
      box-shadow: 0px 0px 4px 0px #2b2b3940;
      min-width: 400px;
      @media screen and (max-width: 1024px) {
        margin-bottom: 20px;
      }

      img {
        margin-left: 20px;
      }
      div {
        margin-left: 30px;
      }
      h3 {
        font-size: 30px;
        margin-top: 0;
      }
    }
  }
}

.tableRow {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100% !important;
  margin: 0;
}
.border {
  height: 105px !important;
  width: 0.5px;
  background-color: #a6a2b5;
  max-width: 100%;
}
.border2 {
  height: 105px !important;
  width: 0.5px;
  background-color: #bcbcbd;
  max-width: 100%;
}
.phoneAnim {
  animation: phone infinite 0.3s;
  transition: all 0.3s ease;
}
.tabelaContent{
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
  }
  &__right{
    width: 12%;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
  &__left{
    width: 12%;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
  &__center{
    width: 75%;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
}